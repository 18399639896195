jQuery(document).ready(function($){

	// Filterable
	var $filterType = $('#filterOptions li.active a').attr('class');
	var $holder = $('ul.holder');
	var $data = $holder.clone();

	$('#filterOptions li a').click(function(e) {

		$('#filterOptions li').removeClass('active');

		var $filterType = $(this).attr('class');
		$(this).parent().addClass('active');

		if ($filterType == 'all') {
			var $filteredData = $data.find('li');
		}
		else {
			var $filteredData = $data.find('li[data-type~=' + $filterType + ']');
		}

		// call quicksand
		$holder.quicksand($filteredData, {
			duration: 800,
			easing: 'easeInOutQuad'
			},
			function() {
				callprettyPhoto();
				galleryHover();
		});
		return false;
	});
	callprettyPhoto();
	function callprettyPhoto() {
		// Work around for PrettyPhoto HTML Validation //
		$('.filterable-item a[data-rel]').each(function() {
			$(this).attr('rel', $(this).data('rel'));
		});

		$("a[rel^='prettyPhoto']").prettyPhoto({social_tools:false, deeplinking: false });
	}

	// Menu
    $('ul.menu').superfish({
		delay:       0,                            // one second delay on mouseout
		animation:   {opacity:'show',height:'show'},  // fade-in and slide-down animation
		speed:       300,                          // faster animation speed
		speedOut:    300,
        autoArrows:  false,                           // disable generation of arrow mark-up
        dropShadows: false                            // disable drop shadows
    });

	var $menu_select = $("<select />");
	$("<option />", {"selected": "selected", "value": "", "text": "Site Navigation"}).appendTo($menu_select);
	$menu_select.appendTo("#primary-menu");

	$("#primary-menu ul li a").each(function(){
		var menu_url = $(this).attr("href");
		var menu_text = $(this).text();

		if ($(this).parents("li").length == 2) { menu_text = '- ' + menu_text; }
		if ($(this).parents("li").length == 3) { menu_text = "-- " + menu_text; }
		if ($(this).parents("li").length > 3) { menu_text = "--- " + menu_text; }
		$("<option />", {"value": menu_url, "text": menu_text}).appendTo($menu_select);
	})

	field_id = "#primary-menu select";
	$(field_id).change(function()
	{
	   value = $(this).attr('value');
	   window.location = value;

	});

	//header stickup
	$('header#site-header').sticky({topSpacing:0,responsiveWidth:true});
	$('.schulart_header').sticky({topSpacing:90,responsiveWidth:true});


	// tooltip
	$("a[data-rel=tooltip]").tooltip();

	// popover demo
	$("a[data-rel=popover]")
		.popover()
			.click(function(e) {
			e.preventDefault()
		});

	// prettyPhoto
	$("a[data-rel=prettyPhoto]")
		.prettyPhoto()
			.click(function(e) {
			e.preventDefault()
		});

	// tabs
    $('#tab1 a, #tab-content a').click(function (e) {
	    e.preventDefault();
	    $(this).tab('show');
    });

	// carousel
	$('#myCarousel, #myCarousel2').carousel();

	(function ($) {
		var termine = $('ul.termine');
		var termineTotal = termine.children().length;
		var terminPosition = 0;

        $('.termineUp').addClass('disabled')
			.click(function(){
			if(terminPosition <= 0) {
				return;
			}
			$('.termineDown').removeClass('disabled');
			$('.termineUp').removeClass('disabled');
			terminPosition--;
			$(termine.children()[terminPosition]).slideDown();

            if(terminPosition <= 0) {
                $('.termineUp').addClass('disabled')
            }
		});
        if(terminPosition >= termineTotal-3) {
            $('.termineDown').addClass('disabled')
        }

		$('.termineDown').click(function(){
			if(terminPosition >= termineTotal-3) {
				return;
			}
            $('.termineDown').removeClass('disabled');
            $('.termineUp').removeClass('disabled');
			$(termine.children()[terminPosition]).slideUp();
			terminPosition++;
            if(terminPosition >= termineTotal-3) {
                $('.termineDown').addClass('disabled')
            }
		});
    })(jQuery);

// end document ready
});

(function ($) {
    function str_rot(input) {
        return input.replace(/[a-zA-Z]/g,function(c){return String.fromCharCode((c<="Z"?90:122)>=(c=c.charCodeAt(0)+13)?c:c-26);});
    }

    $.fn.fixMailTo = function () {
        return this.each(function() {
        	var $this = $(this);
            return $this.attr('href', str_rot($this.attr('href')));
        })
    };

    $.fn.fixMail = function() {
        return this.each(function() {
            var $this = $(this);
        	return $this.html(str_rot($this.text()));
        });
	}
})(jQuery);



// Animated
	$(window).load(function() {


		$('#loading').fadeOut('400');
		setTimeout("$('.outer-wrapper').removeClass('hidden')",400);
		setTimeout("$('.infobar').addClass('animated fadeIn').removeClass('hidden')",400);
		setTimeout("$('#logo').addClass('animated bounceInLeft').removeClass('hidden')",1000);
		setTimeout("$('.featured-wrapper').addClass('animated fadeIn ').removeClass('hidden')",2000);
		setTimeout("$('#primary-menu ul').addClass('animated fadeIn').removeClass('hidden')",2000);
		setTimeout("$('#primary-menu ul li').removeClass('animated fadeIn')",2000);
		setTimeout("$('.searchform').addClass('animated fadeIn').removeClass('hidden')",400);
		setTimeout("$('#home-slider').addClass('animated fadeIn ').removeClass('hidden')",2000);
		setTimeout("$('#myCarousel').addClass('animated fadeIn ').removeClass('hidden')",2000);
		setTimeout("$('#home-slogan').addClass('animated bounceInDown').removeClass('hidden')",2500);
		setTimeout("$('.carousel-caption, #outer-content, #outer-content3').addClass('animated fadeIn').removeClass('hidden')",2000);
		setTimeout("$('.footer').addClass('animated fadeIn').removeClass('hidden')",2000);
		setTimeout("$('.homepage_widgets_divider, .homepage_widgets_outer').addClass('animated fadeIn').removeClass('hidden')",2000);

		setTimeout("$('.page-header').addClass('animated fadeIn').removeClass('hidden')",2000);
		setTimeout("$('.crumb').addClass('animated fadeIn').removeClass('hidden')",2000);

		setTimeout("$('.one').addClass('animated fadeIn').removeClass('hidden')",2000);
		setTimeout("$('.two').addClass('animated fadeIn').removeClass('hidden')",2000);
		setTimeout("$('.three').addClass('animated fadeIn').removeClass('hidden')",2000);
		setTimeout("$('.four').addClass('animated fadeIn').removeClass('hidden')",2000);
		setTimeout("$('.five').addClass('animated fadeIn').removeClass('hidden')",2000);
		setTimeout("$('.hidden').addClass('animated fadeIn').removeClass('hidden')",2000);

		if (window.opera) {
			setTimeout("$('.navbar-fixed-top').removeClass('hidden animated fadeIn')",800);
			setTimeout("$('#logo').removeClass('hidden animated bounceInLeft')",2000);
			setTimeout("$('#primary-menu ul').removeClass('hidden animated fadeIn')",3000);
			setTimeout("$('#primary-menu ul li').removeClass('hidden animated fadeIn')",3000);
		}

		//fix flickering of navigation in chrome
		setTimeout("$('#primary-menu>ul').removeClass('hidden animated fadeIn')",3000);
        setTimeout("$('.footer').removeClass('animated fadeIn')",3000);

        $('a.mailto').fixMailTo();
        $('.mail').fixMail();
	});

	// Twitter
	function getMonth(month) {
		var months = ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'];
		return months[month];
	}
	function doubleDigit(integer) {
		if (integer < 10) {
			integer = '0' + integer;
		}
		return integer;
	}
	function parseTwitterDate(text) {
		var newtext = text.replace(/(\d{1,2}[:]\d{2}[:]\d{2}) (.*)/, '$2 $1');
		return newtext.replace(/(\+\S+) (.*)/, '$2 $1')
	}
	function parseLinks(tweet) {
		return tweet.replace(/[A-Za-z]+:\/\/[A-Za-z0-9-_]+\.[A-Za-z0-9-_:%&\?\/.=]+/g, function(tweet) {
			return tweet.link(tweet);
		});
	};

	function showTimeline(data) {
		var timeline = $('<ul class="tweeting">');
		$(data).each(function() {
			var _this = $(this).get(0);
			var date = new Date(parseTwitterDate(_this.created_at));
			var created_at = [
				date.getDate() + 1,
				getMonth(date.getMonth()),
				[
				doubleDigit(date.getHours()),
				doubleDigit(date.getMinutes())
				].join(':')
				].join(' ');
			timeline.append(
			$('<li><i class="icon-twitter"></i>').append(
			$('<span>').html(
			parseLinks(_this.text))));
		});
		$('.timeline').append(timeline).find("a").attr('target', '_blank');

	}
equalheight = function(container){

	var currentTallest = 0,
	    rowDivs = new Array(),
	    $el,
	    topPosition = 0;
	$(container).each(function() {

	  $el = $(this);
	  $($el).height('auto')
	  rowDivs.push($el);
	  currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
	});
	for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
		rowDivs[currentDiv].height(currentTallest);
	}
}

	$(window).load(function() {
	  equalheight('.services_big div');
		equalheight('.profil header');
		equalheight('.profilfach');
		equalheight('.pflichtfachE');
		equalheight('.pflichtfachJ1');
        $(".owl-carousel").owlCarousel({
			margin: 40,
            nav: false,
			dots: true
		});
	});


	$(window).resize(function(){
	  equalheight('.services_big div');
		equalheight('.profil header');
		equalheight('.profilfach');
		equalheight('.pflichtfachE');
		equalheight('.pflichtfachJ1');
        equalheight('article.post.span4');
		//equalheight('.leitbild div div');
	});
